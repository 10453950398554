body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html { 
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 3px;
}

body::-webkit-scrollbar-track {
  background: rgb(21, 67, 96);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(202, 202, 207);
  border-radius: 25px;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

@keyframes heartAnim {
  0% {transform: scale(1);}
  50% {transform: scale(1.25);}
  100% {transform: scale(1);}
}

@keyframes heartAnim2 {
  0% {transform: scale(1);}
  50% {transform: scale(0.75);}
  100% {transform: scale(1);}
}

@keyframes heartAnim3 {
  0% {transform: scale(1);}
  50% {transform: scale(1.1);}
  100% {transform: scale(1);}
}

@keyframes heartOpacity {
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes arrowAnim {
  0% {transform: translateY(0);}
  50% {transform: translateY(-10px);}
  100% {transform: translateY(0);}
}